export const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  // SUPPORT
  SUPPORT: 'support',
  FAQ: 'faq',
  TERMS_OF_USE: 'terms-of-use',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  PRIVACY_POLICY: 'privacy-policy',
  // ABOUT US
  ABOUT_US: 'about-us',
  ABOUT_COOKX: 'about-cookx',
  ABOUT_SELLING: 'about-selling',
  ABOUT_STUDIO: 'about-studio',
  CONTACT_US: 'contact-us',
  CAREERS: 'careers',
  // SIGNUP
  SIGNUP: 'signup',
  REGISTER_COMPLETE: 'register-complete',
  // PRESS COVERAGE
  PRESS_COVERAGE: 'press-coverage',
  PRESS_COVERAGE_LIST: 'press-coverage-list',
  PRESS_COVERAGE_DETAIL: 'press-coverage-detail',
  // VERIFY VOUCHER
  VERIFY_VOUCHER: 'voucher-redeem',
  //PRODUCT Redemption
  PRODUCT_REDEMPTION: 'product-redeem',
  //EARN Redemption
  EARN_REDEMPTION: 'earn',
  // RECIPE
  RECIPE: 'recipe',
  RECIPE_LIST: 'recipe-list',
  RECIPE_BAKERY: 'recipe-bakery',
  RECIPE_ASIAN: 'recipe-asian',
  RECIPE_WESTERN: 'recipe-western',
  RECIPE_BAKERY_LIST: 'recipe-bakery-list',
  RECIPE_ASIAN_LIST: 'recipe-asian-list',
  RECIPE_WESTERN_LIST: 'recipe-western-list',
  RECIPE_BAKERY_DETAILS: 'recipe-bakery-details',
  RECIPE_ASIAN_DETAILS: 'recipe-asian-details',
  RECIPE_WESTERN_DETAILS: 'recipe-western-details',
  // OTHER
  QR_LINK: 'qr-link'
});
