import { USER_EARN } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  SHARED_OPEN_LOADING,
  SHARED_CLOSE_LOADING
} from '@/core/store/shared.module';
import dateTimeFormat from '@/core/filters/dateTimeFormat.filter';
import currencyFormat from '@/core/filters/currency-format.filter';

/* Define Module Name */
const MODULE_NAME = 'userEarn/';

/* Define Action Name */
const A_GET_USER_EARN = 'getUserEarn';
const A_UPDATE_CHECK_USER_EARN = 'updateCheckUserEarn';

/* Define Reset State Action Name */
const A_INITIAL_GET_USER_EARN_STATE = 'initialGetUserEarnState';
const A_INITIAL_UPDATE_CHECK_USER_EARN_STATE =
  'initialUpdateCheckUserEarnState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USER_EARN = 'setgetUserEarn';
const M_UPDATE_CHECK_USER_EARN = 'setupdateCheckUserEarn';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_USER_EARN_STATE = 'setInitialGetUserEarnState';
const M_INITIAL_UPDATE_CHECK_USER_EARN_STATE =
  'setInitialUpdateCheckUserEarnState';

/* Define Export Name */
export const USER_EARN_GET_USER_EARN = MODULE_NAME + A_GET_USER_EARN;
export const USER_EARN_UPDATE_CHECK_USER_EARN =
  MODULE_NAME + A_UPDATE_CHECK_USER_EARN;

/* Define Reset State Name */
export const USER_EARN_INITIAL_GET_USER_EARN_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_EARN_STATE;
export const USER_EARN_INITIAL_UPDATE_CHECK_USER_EARN_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_CHECK_USER_EARN_STATE;

const state = {
  userEarn: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  checkUserEarn: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_USER_EARN]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await USER_EARN.getUserEarn(data)
      .then((response) => {
        result = response;
        commit(M_GET_USER_EARN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_USER_EARN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  async [A_UPDATE_CHECK_USER_EARN]({ dispatch, commit }, { data }) {
    dispatch(`${SHARED_OPEN_LOADING}`, null, { root: true });
    let result = null;
    await USER_EARN.updateCheckUserEarn(data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_CHECK_USER_EARN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_CHECK_USER_EARN, result);
        dispatch(`${SHARED_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_USER_EARN_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_EARN_STATE);
  },
  [A_INITIAL_UPDATE_CHECK_USER_EARN_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_CHECK_USER_EARN_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_USER_EARN](state, result) {
    if (result.code === 0) {
      let d = result.data;
      let data = {};

      if(d) {
        data = { ... data,
          merchantName: d.merchant_name,
          branchName: d.branch_name,
          voucherCode: d.voucher_code,
          billAmount: currencyFormat(d.price),
          receivedPayment: currencyFormat(d.total_price),
          value: `${d.value_type == 2 ? d.value + '%' : '- RM ' + d.value}`,
          valueType: d.value_type,
          valueAmount: d.value,
          status: d.status,
          statusDescription: d.status_description,
          redemptionAt: dateTimeFormat(d.redemption_at),
          voucherUseType: d.voucher_use_type}
      }

      data.countdownTime = result.countdown_time;
      state.userEarn = {
        code: result.code,
        complete: true,
        data: data,
        message: null
      };
    } else {
      state.userEarn = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result.message)
      };
    }
  },
  [M_UPDATE_CHECK_USER_EARN](state, result) {
    let d = result;

    state.checkUserEarn = {
      code: result.code,
      complete: true,
      data: d.data,
      message: d.message
    };
  },
  [M_INITIAL_GET_USER_EARN_STATE](state) {
    let d = state.userEarn.data;
    state.userEarn = {
      code: 0,
      complete: false,
      data: { ...d },
      message: null
    };
  },
  [M_INITIAL_UPDATE_CHECK_USER_EARN_STATE](state) {
    let data = state.checkUserEarn.data;
    let code = state.checkUserEarn.code;
    let message = state.checkUserEarn.message;

    state.checkUserEarn = {
      code: code,
      complete: false,
      data: { ...data },
      message: message
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
